html {
    background-color: #ffffff;
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
    padding: 0;
    margin: 0;
}
:root {
    --text-primary-color: #5a5076;
    --heаding-primary-color: #2e2056;
    --bg-primary-color: #02a08d;
    --link-primary-color: #00e7ce;
    --button-primary-color: #00e7ce;
    --bg-circle-color: #25c6e8;
    --paragrapgh-color: #ced1ee;
    --li-color: #384575;
    --li-circle-color: #72dbf6;
    --bg-box: #f5f4f7;
}
.container {
    margin-left: auto;
    margin-right: auto;
    max-width: 1149px;
}

body {
    font-size: 16px;
    line-height: 30px;
    font-family: 'Raleway', sans-serif;
    color: #ffffff;
    background: #ffffff;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    scroll-behavior: smooth;
    min-width: 320px;
    margin: 0px;
}

h1 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 58px;
    line-height: 136.4%;
    color: #ffffff;
    margin: 20px 0;
}

h2 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 136.4%;
    color: var(--heаding-primary-color);
    margin: 20px 0;
}

h3 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 136.4%;
    color: var(--heаding-primary-color);
    margin: 20px 0;
}

p {
    font-family: Raleway;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    text-align: left;
    color: var(--text-primary-color);
    padding: 10px 0;
}

.text-center {
    text-align: center;
}

.text-white {
    color: #ffffff;
}

/* Banner */
.banner {
    background: #ffffff;
    box-shadow: 0px 4px 105px rgba(34, 48, 74, 0.2);
    border-radius: 0px 0px 10px 10px;
    padding: 10px 20px 10px 10px;
    display: grid;
    gap: 30px;
    align-items: center;
    grid-template-columns: 0.8fr 2fr 0.7fr;
    position: relative;
    z-index: 3;
}
.banner .logo-box {
    background: #eaf2f8;
    border-radius: 10px;
}
.banner .logo-box a {
    background: url(../image/domainer_logo.svg);
    background-repeat: no-repeat;
    background-position: center center;
    color: transparent;
    display: block;
    font-size: 0;
    padding: 20px 30px;
    height: 138px;
}
.banner .title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 30px;
    margin-bottom: 15px;
    color: #090909;
}
.banner .info {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #7d7f82;
    position: relative;
    margin-left: 15px;
    padding: 0;
}
.banner .info a {
    color: #653e8d;
}
.banner .info:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 6px;
    height: 100%;
    margin-left: -15px;
    border-radius: 10px;
    background: #f27558;
}
.banner .domaner-btn {
    background: #f27558;
    transition: all 0.5s ease-in-out;
    border-radius: 10px;
    padding: 23px 5px;
    display: block;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    text-align: center;
    color: #000000;
}
.banner .domaner-btn:hover {
    background-color: rgb(249, 173, 141);
}
@media only screen and (max-width: 1090px) {
    .banner .title {
        font-size: 22px;
        line-height: 32px;
        margin-bottom: 10px;
    }
    .banner .info {
        font-size: 18px;
    }
    .banner .domaner-btn {
        font-size: 18px;
    }
    .banner .domaner-btn {
        font-size: 18px;
        line-height: 22px;
        display: table;
        margin: 0 auto;
        padding: 23px 30px;
    }
}
@media only screen and (max-width: 940px) {
    .banner {
        grid-template-columns: 1fr;
        padding: 10px;
    }
    .banner .logo-box a {
        background: url(../image/domainer_logo_mob.svg);
        background-repeat: no-repeat;
        background-position: center center;
        height: 66px;
    }
    .banner .title {
        line-height: 24px;
        text-align: center;
    }
    .banner .info {
        font-size: 16px;
        line-height: 20px;
        text-align: center;
    }
    .banner .info:before {
        content: none;
    }
}
@media only screen and (max-width: 440px) {
    .banner .domaner-btn {
        display: block;
        padding: 23px 0px;
    }
}

.grid {
    display: grid;
    grid-gap: 20px;
    gap: 20px;
}

.col2 {
    grid-template-columns: 1fr 1fr;
}

.col2-1 {
    grid-template-columns: 2fr 1fr;
}

.col1-2 {
    grid-template-columns: 1fr 2fr;
}

a {
    text-decoration: none;
    color: var(--link-primary-color);
}
a.text-underline {
    text-decoration: underline;
}

/* body */
.scroll-to-link > * {
    pointer-events: none;
}
.site-header {
    width: 100%;
    background: var(--bg-primary-color);
    padding-bottom: 20px;
    position: relative;
}

.site-logo {
    position: relative;
    z-index: 3;
    padding: 20px 0;
}

.site-logo .main-logo,
.footer .main-logo {
    position: relative;
    display: inline-block;
    width: 200px;
    height: 30px;
    vertical-align: middle;
    font-size: 0;
    background: url(../image/logo.svg) no-repeat 0 0 / contain;
}

.intro-primary {
    width: 100%;
    background: var(--bg-primary-color);
    padding-bottom: 80px;
    margin-bottom: 70px;
    position: relative;
}

.blur-circle {
    position: absolute;
    width: 30%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background: var(--bg-circle-color);
    filter: blur(150px);
}

.blur-circle-right {
    position: absolute;
    width: 20%;
    height: 25%;
    top: 0;
    left: auto;
    right: 0;
    bottom: 0;
    background: var(--bg-circle-color);
    filter: blur(150px);
}

.blur-circle-bottom {
    position: absolute;
    width: 26%;
    height: 14%;
    top: 50%;
    left: 30%;
    right: 0;
    bottom: 0;
    background: var(--bg-circle-color);
    filter: blur(150px);
}

.intro-primary .title {
    position: relative;
    z-index: 3;
}
.intro-entry {
    font-family: Raleway;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: #ffffff;
    margin-bottom: 60px;
}
.main-image {
    background-image: url(../image/top-image.webp);
    height: 500px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.sub-title {
    padding: 0 100px;
}
.gradient-btn {
    background: #e7b7ff;
    background: linear-gradient(188.43deg, #017a6c 3.27%, rgba(3, 160, 141, 0) 96.37%);
    border-radius: 69px;
    height: 82px;
    padding: 3px;
    width: 354px;
}
.gradient-btn .inner {
    position: relative;
    height: 100%;
    width: 100%;
    border: none;
    border-radius: 69px;
    color: #fff;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: bold;
    text-align: center;
    transition: font-size 0.4s linear;
    background: linear-gradient(95.18deg, #9161c1 24.19%, #663e8e 85.99%);
}

.gradient-btn .inner:after {
    content: '';
    opacity: 0;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: inherit;
    transition: opacity 0.4s linear;
    background: linear-gradient(94.06deg, #b374d3 1.25%, #870ffe 71.25%);
}

.gradient-btn:hover .inner:after {
    opacity: 1;
}

.gradient-btn a,
.gradient-btn span {
    color: white;
    text-decoration: none;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 2;
}
.contact-btn.gradient-btn {
    height: 69px;
    width: 200px;
    position: relative;
    background: none;
    margin: 0 auto;
}

/*
.contact-btn.gradient-btn:hover:before {
    background: #fff;
    border-radius: 2px;
    bottom: 0;
    box-shadow: 0 4px 15px rgb(26 70 79 / 30%);
    color: #4A4A4A;
    content: "Services under construction";
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: bold;
    line-height: 27px;
    text-align: center;
    height: inherit;
    left: 0;
    opacity: .95;
    position: absolute;
    transform: translateY(-80px);
    width: inherit;
    border-radius: 69px;
}
.contact-btn.gradient-btn:hover:after {
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    border-top: 8px solid #fff;
    clear: both;
    content: "";
    height: 0;
    left: 43%;
    opacity: .95;
    position: absolute;
    transform: translateY(-16px);
    width: 0;
    top: 3px;
}
*/
.button {
    display: inline-block;
    padding: 15px 30px;
    color: #ffffff;
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 27px;
    text-decoration: none;
    border-radius: 69px;
    position: relative;
    background-color: #ccc;
    background-image: linear-gradient(95.18deg, #8d68c2 23.23%, #555f97 85.02%);
    -webkit-backface-visibility: hidden;
    z-index: 1;
    white-space: nowrap;
}
.button:after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 69px;
    background-image: linear-gradient(95.11deg, #5ab2f4 3.48%, #02c2ff 3.49%, #58d7ff 85.01%);
    transition: opacity 0.5s ease-out;
    z-index: 2;
    opacity: 0;
}
.button:hover:after {
    opacity: 1;
}
.button span {
    position: relative;
    z-index: 3;
}
/* table */
.t-top-title {
    background-image: url(../image/arrows2.png), url(../image/arrows1.png);
    background-position: left top, right top;
    background-repeat: no-repeat, no-repeat;
}

.t-top-tabs {
    display: table;
    margin: 0 auto;
}

ul#horizontal-list {
    min-width: 696px;
    list-style: none;
    padding-top: 20px;
    margin: 40px 0;
}

ul#horizontal-list li {
    display: inline;
    padding: 25px 5px;
    position: relative;
    background: #ffffff;
    box-shadow: 0px 4px 19px rgba(46, 32, 86, 0.1);
    border-radius: 10px;
    margin: 0 20px;
}

ul#horizontal-list li a {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #a19bb3;
    text-decoration: none;
    padding: 17px 23px;
    border-bottom: 3px solid #a19bb3;
    border-radius: 2px;
    transition-duration: 0.2s;
}

ul#horizontal-list li.active a {
    font-weight: 600;
    color: #2e2056;
    border-bottom: 3px solid var(--button-primary-color);
}

ul#horizontal-list li:hover {
    cursor: pointer;
}

ul#horizontal-list li:hover a {
    border-bottom: 3px solid var(--button-primary-color);
}

ul#horizontal-list li.active a::after {
    content: '';
    position: absolute;
    left: 45%;
    top: 94%;
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-top: 12px solid var(--button-primary-color);
    clear: both;
    z-index: 2;
}

ul#horizontal-list li.active::after {
    content: '';
    position: absolute;
    left: 45%;
    top: 100%;
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-top: 12px solid #ffffff;
    clear: both;
}

.t-section {
    background: var(--bg-primary-color);
    border-radius: 26px;
    padding: 15px;
    margin: 30px 0;
}

.mytabs {
    /* display: none; */
    height: 0;
    opacity: 0;
}

.mytabs > div {
    overflow: hidden;
}

.mytabs.active {
    height: auto;
    opacity: 1;
    /* display: block; */
    transition: opacity 0.3s linear;
}

.d-none {
    display: none;
}

table.home-table {
    width: 100%;
    border: 0;
    border-collapse: separate;
    border-spacing: 0 10px;
    margin-top: -10px;
    font-family: Raleway;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: #2e2056;
}

.home-table thead tr {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #c3e8e4;
    text-transform: uppercase;
}

.home-table tbody tr {
    padding: 5px 5px 5px 20px;
    border-radius: 28px;
    margin: 15px 0;
}

table.home-table td {
    border: solid 1px #f9fbff;
    border-style: solid none;
    padding: 10px;
    background-color: #f9fbff;
    text-align: center;
    max-width: 250px;
    position: relative;
}

table.home-table td:not(:first-of-type, :last-of-type, :nth-of-type(5))::after {
    content: '';
    height: 80%;
    width: 1px;
    position: absolute;
    right: 0;
    top: 10px;
    background-color: #f1f4f9;
}

table.home-table td:first-child {
    border-left-style: solid;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}

table.home-table td:last-child {
    border-right-style: solid;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
}
table.home-table li {
    margin-left: 20px;
}
.t-logo {
    display: inline-flex;
}
.t-logo .logo {
    content: '';
    width: 210px;
    height: 90px;
    background-position: center;
    background-repeat: no-repeat;
    float: left;
    font-size: 0px;
    background-color: #f1f4f9;
    border-radius: 25px;
    padding: 15px 0px;
}
.t-logo .logo.logo-opensea {
    background-image: url(../image/logo-opensea.png);
}
.t-logo .logo.logo-binance {
    background-image: url(../image/binance_logo.png);
}
.t-logo .logo.logo-crypto {
    background-image: url(../image/logo-crypto-com.png);
}
.t-logo .logo.logo-veve {
    background-image: url(../image/veve_logo.png);
}
.t-logo .logo.logo-rarible {
    background-image: url(../image/rarible_logo.png);
}
.t-logo .logo.logo-pantograph {
    background-image: url(../image/logo-pantograph.png);
}
.t-logo .logo.logo-sketchar {
    background-image: url(../image/logo-sketchar.png);
}
.t-logo .logo.logo-ipfs {
    background-image: url(../image/logo-ipfs.png);
}
.t-logo .logo.logo-lucidpix {
    background-image: url(../image/logo-lucidpix.png);
}
.t-logo .logo.logo-qwnerfy {
    background-image: url(../image/qwnerfy_logo.png);
}
.t-logo .logo.logo-coinbase {
    background-image: url(../image/coinbase_logo.png);
}
.t-logo .logo.logo-enjin {
    background-image: url(../image/logo-enjin.png);
}
.t-logo .logo.logo-ellipal {
    background-image: url(../image/logo-ellipal.png);
}
.t-logo .logo.logo-metamask {
    background-image: url(../image/metamask_logo.png);
}
.t-logo .logo.logo-rainbow {
    background-image: url(../image/rainbow_logo.png);
}
.t-logo .logo.logo-oxuniverse {
    background-image: url(../image/oxuniverse_logo.png);
}
.t-logo .logo.logo-splinterlands {
    background-image: url(../image/splinterlands_logo.png);
}
.t-logo .logo.logo-cropbytes {
    background-image: url(../image/cropbytes_logo.png);
}
.t-logo .logo.logo-forestknight {
    background-image: url(../image/forestknight_logo.png);
}
.t-logo .logo.logo-chainmonsters {
    background-image: url(../image/chainmonsters-logo.png);
}
.t-os-icon {
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: auto;
    height: 30px;
    margin: 0 auto;
    width: 50px;
    display: inline-block;
    vertical-align: middle;
    font-size: 0;
}
.t-os-icon.iphone-android {
    background-image: url(../image/iphone.svg), url(../image/android.svg);
    background-position: left, right;
}
.t-os-icon.android {
    background-image: url(../image/android.svg);
    background-position: center;
}
.t-rating {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    min-width: 80px;
}
.t-rating .icon {
    content: '';
    width: 20px;
    height: 20px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    float: left;
    margin: 0px 10px 0 0px;
    font-size: 0px;
    background-image: url(../image/star.png);
}
.t-rating img:first-child,
.t-os img:first-child {
    margin-right: 15px;
}

ul.list-highlights {
    text-align: left;
    list-style: none;
    font-weight: 500;
}

ul.list-highlights li::before {
    content: '\2022';
    font-family: Poppins;
    color: var(--link-primary-color);
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
}

.what-is {
    background: #f5f4f7;
    border-radius: 30px;
    margin: 70px 0 60px;
}

.what-is .inner-img {
    display: flex;
}

.what-is .inner {
    text-align: left;
    padding: 50px 50px 50px 30px;
}

.types {
    margin: 30px 0;
    position: relative;
}

.types .type {
    background: #ffffff;
    box-shadow: 0px 4px 19px rgba(46, 32, 86, 0.1);
    border-radius: 30px;
    padding: 30px;
    margin: 30px 0;
}

.types .type:first-child {
    margin-top: 0px;
}

.types .type .type-title {
    background-size: auto;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: left;
    background-size: contain;
}
.type-title.marketplace_icon {
    background-image: url(../image/trophy.png);
}
.types .type .type-title h3 {
    position: relative;
    left: 40px;
}
.grey-box {
    background: var(--bg-box);
    padding: 30px;
    border-radius: 30px;
    margin: 30px 0;
}
.single-row {
    border-radius: 30px;
    padding: 30px;
    margin: 20px 0;
    display: flex;
    align-items: center;
}
.single-row a {
    content: '';
    width: 250px;
    height: 120px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-color: white;
    float: left;
    margin: 0px 30px 0 0px;
    font-size: 0px;
    box-shadow: 11px 14px 60px rgba(46, 92, 118, 0.2);
    border-radius: 20px;
}
.single-row a.first-icon {
    background-image: url(../image/first-icon.webp);
}
.single-row a.second-icon {
    background-image: url(../image/second-icon.png);
}
.single-row a.third-icon {
    background-image: url(../image/war-of-crypta-icon.png);
}
.compatibility-box {
    background: #566098;
    padding: 100px 0 80px 0;
}

.compatibility-title {
    margin: 40px 0;
}

.compatibility-box .iphone,
.compatibility-box .android {
    position: relative;
    z-index: 2;
}

.compatibility-box .iphone::after,
.compatibility-box .android::after {
    content: '';
    background-size: contain !important;
    background-repeat: no-repeat !important;
    position: absolute;
    right: 0;
    bottom: 0px;
    opacity: 0.2;
    filter: blur(6px);
    -webkit-filter: blur(6px);
}

.compatibility-box .iphone::after {
    background: url(../image/iphone-bg.webp) transparent;
    background-position: left;
    top: -75px;
    left: -64px;
    z-index: -1;
}

.compatibility-box .android::after {
    background: url(../image/android-bg.webp) transparent;
    background-position: right;
    top: 0px;
    left: 0px;
    z-index: -1;
}

/* F&Q */
.faq-wrapper {
    margin-top: 60px;
}

.faq:not(:first-of-type) {
    border-top: 1px solid #edecee;
}

.faq-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}
.faq.expanded .faq-title {
    padding-bottom: 20px;
}

.faq-btn {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 20px;
    width: 20px;
    background-color: transparent;
    border: none;
}

.faq-btn:hover {
    cursor: pointer;
}

.faq {
    padding: 10px 30px;
    transition: all 0.1s ease-in;
}
.faq.collapsed .faq-btn {
    background-image: url(../image/faq-expand.svg);
}

.faq.collapsed .faq-body {
    display: none;
}

.faq.expanded .faq-btn {
    background-image: url(../image/faq-collapse.svg);
    background-size: contain;
}

.faq.expanded {
    background: #f5f4f7;
    border-radius: 30px;
    border: none;
}

.faq.faq.expanded + .faq {
    border-top: 0px;
}

.faq.expanded .faq-title h3 {
    margin-bottom: 0px;
}

.faq .faq-title h3 {
    pointer-events: none;
}

.faq-listing {
    margin-top: 30px;
}

.read-more-faq {
    width: 100%;
    border: 1px solid var(--link-primary-color);
    border-radius: 69px;
    height: 59px;
    background: transparent;
    font-weight: bold;
    font-size: 16px;
    font-family: Poppins;
    font-style: normal;
    line-height: 24px;
    color: var(--link-primary-color);
}

.read-more-faq:active {
    background-color: #e2fcfc;
}

.conclution-box {
    background: linear-gradient(94.3deg, #04c0aa -1.78%, #03a08d 84.81%);
    border-radius: 30px;
    padding: 30px 100px 50px;
    position: relative;
    top: 70px;
}

/* Footer */
.footer {
    background: #1d1535;
    padding: 100px 0 20px;
    color: #9a91b6;
}

.footer-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media (min-width: 1300px) {
    .container {
        min-width: 1350px;
    }
    a.button {
        min-width: 170px;
    }
    table.home-table td {
        max-width: 100%;
    }
    .single-row a {
        max-width: 350px;
    }
}
@media (max-width: 1390px) {
    .container {
        padding: 0 25px;
    }
}
@media only screen and (min-width: 868px) and (max-width: 1080px) {
    .container {
        padding: 0 25px;
    }
    a.secondary {
        font-size: 14px;
    }
    .col2-1,
    .col1-2,
    .col2 {
        grid-template-columns: 1fr;
    }
    .main-image {
        display: none;
    }
    .what-is .inner-img {
        justify-content: center;
    }
    .t-logo .logo {
        width: 200px;
    }
    .t-rating {
        justify-content: left;
    }
    .types .type .type-title h3 {
        max-width: 500px;
    }
}

.m-visible {
    display: none;
}

.m-hidden {
    display: initial;
}

@media only screen and (max-width: 867px) {
    .mobile-detector {
        display: none;
    }

    .m-hidden {
        display: none;
    }

    .m-visible {
        display: initial;
    }

    .m-slide-down {
        -moz-transition: height 0.5s;
        -ms-transition: height 0.5s;
        -o-transition: height 0.5s;
        -webkit-transition: height 0.5s;
        transition: height 0.5s;
        height: 0;
        overflow: hidden;
    }

    .container {
        padding-left: 25px !important;
        padding-right: 25px !important;
    }
    h1 {
        font-weight: 600;
        font-size: 32px;
        line-height: 38px;
    }
    h2 {
        font-size: 24px;
        line-height: 36px;
    }
    h3 {
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
    }
    p {
        font-weight: 500;
        font-size: 15px;
        line-height: 22px;
    }
    .col2-1,
    .col1-2,
    .col2 {
        grid-template-columns: 1fr;
    }
    .sub-title {
        padding: 0px;
    }
    .main-image,
    .blur-circle-bottom,
    .blur-circle-right,
    .overlay {
        display: none;
    }
    .site-header,
    .intro-primary {
        background: var(--bg-primary-color);
    }
    .intro-primary {
        background-image: url(../image/arrows2.png), url(../image/arrows1.png);
        background-position: left, right;
        background-repeat: no-repeat, no-repeat;
        margin-bottom: 20px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        padding-bottom: 30px;
    }
    .intro-entry {
        margin-bottom: 30px;
    }
    .logo {
        padding: 0px;
    }
    .intro-entry {
        font-weight: 500;
        font-size: 16px;
        line-height: 26px;
    }
    .gradient-btn {
        width: auto;
    }
    a.button {
        display: block;
        padding: 20px;
        text-align: center;
        min-width: auto;
        box-shadow: none;
    }
    .inner-img img {
        width: 100%;
    }
    /* Table */

    .m-slider-nav {
        width: 120px;
        margin: 8px auto 0px;
    }

    .m-slider-nav ul {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        flex-wrap: nowrap;
    }

    .m-slider-nav ul li {
        list-style-type: none;
        width: 7px;
        height: 7px;
        background-color: #dedde2;
        border-radius: 12px;
    }

    .m-slider-nav ul li.active {
        background-color: var(--button-primary-color);
    }

    .t-top-tabs {
        overflow: hidden;
        display: block;
    }
    .t-top-title {
        background-image: none;
    }

    ul#horizontal-list li {
        transition: margin 0.2s linear;
    }

    ul#horizontal-list {
        min-width: auto;
        width: 1600px;
    }
    ul.list-highlights {
        padding-left: 0px;
    }
    ul.list-highlights li {
        margin-left: 10px;
    }
    .t-logo .logo {
        height: auto;
        width: 100% !important;
    }
    .t-os {
        margin: inherit;
    }
    .t-logo {
        background: #f1f4f9;
        border-radius: 25px;
        padding: 20px 10px;
        height: auto;
        width: 100% !important;
    }
    .t-logo .logo {
        display: block;
        width: 100px;
        height: auto;
        font-size: 0;
        text-indent: -4000px;
        background-color: transparent;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        max-width: 200px;
        margin: 0 auto;
        float: none;
    }
    .t-rating {
        font-size: 14px;
        line-height: 21px;
        color: #2e2056;
        justify-content: left;
    }
    .home-table thead {
        display: none;
    }
    .home-table tbody tr {
        display: table;
        background: #f9fbff;
        border-radius: 20px;
        padding: 10px;
        width: 100%;
        max-width: -webkit-fill-available;
    }
    .home-table td {
        border: none !important;
        position: relative;
        text-align: left !important;
        padding: 10px 5px !important;
        display: inline-table;
        max-width: 350px !important;
    }

    .home-table td:before {
        position: absolute;
        top: -6px;
        left: 4px;
        width: 45%;
        padding-right: 10px;
        white-space: nowrap;
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        text-align: left;
        text-transform: uppercase;
        color: #96a0ae;
    }
    .home-table td:nth-of-type(1):before {
        content: '';
    }
    .home-table td:nth-of-type(2):before {
        content: 'Type';
    }
    .home-table td:nth-of-type(3):before {
        content: 'Highlights';
    }
    .home-table td:nth-of-type(4):before {
        content: 'OS';
    }
    .home-table td:nth-of-type(5):before {
        content: 'Rating';
    }

    .home-table td:nth-of-type(1),
    .home-table td:nth-of-type(2) {
        width: 48%;
        line-height: 20px;
    }
    .home-table td:nth-of-type(4),
    .home-table td:nth-of-type(5) {
        width: 28%;
    }
    .home-table td:nth-of-type(3) {
        float: right;
        width: 62%;
    }
    .home-table td:nth-of-type(6) {
        width: 100%;
        display: flow-root;
        margin: 0 auto;
    }

    table.home-table td:not(:first-of-type, :last-of-type, :nth-of-type(4))::after {
        background-color: transparent;
    }

    .what-is .inner {
        padding: 0 20px 20px;
    }
    .what-is {
        margin: 40px 0;
    }
    #types .sub-title p {
        margin-bottom: 0px;
    }
    .types .type .type-title h3 {
        max-width: 70%;
        white-space: break-spaces;
    }
    .single-row {
        display: grid;
        margin: 30px 0;
        padding: 0px;
    }
    .single-row a {
        margin: 0 0 30px 0;
        width: 100%;
        max-width: 300px;
    }
    .compatibility-box {
        padding: 50px 0 30px 0;
    }
    .compatibility-box .compatibility-title h2,
    .compatibility-box .compatibility-title p,
    .t-top-title h2,
    .t-top-title p {
        text-align: left;
    }
    .compatibility-box .compatibility-title {
        position: relative;
    }
    .compatibility-box .compatibility-title::after {
        content: '';
        height: 2px;
        width: 80%;
        position: absolute;
        right: 40px;
        background-color: #4e3892;
        bottom: -30px;
        box-shadow: 0px 2px 2px rgb(0 0 0 / 40%);
    }
    .faq-wrapper {
        margin-top: 30px;
    }
    .faq {
        padding: 20px 15px;
    }
    .faq.expanded {
        border-radius: 15px;
    }
    .conclution-box {
        padding: 15px;
        margin: 0 8px;
        top: 50px;
    }
    .footer {
        padding-top: 80px;
    }
    .footer-inner {
        justify-content: center;
        padding-bottom: 30px;
        display: grid;
        text-align: center;
    }
}
@media only screen and (max-width: 767px) {
    .types .type .type-title h3 {
        margin-left: 40px;
    }
}

